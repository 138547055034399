* {
  font-family: Arial, Helvetica, sans-serif;
  /* line-height: 1.2; */
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* default classes starts */
.amber {
  color: #e0a74f;
  font-weight: bold;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

/* default classes ends */

/* Navbar CSS */
.navbar {
  padding: 0.5rem 1rem 0rem;
  background-color: #0e0d0d !important;
  position: fixed;
  width: 100%;
  z-index: 7;
}

.navbar-brand .logo {
  height: 70px;
}

.navbar-nav .nav-link {
  margin-right: 1rem;
  position: relative;
  color: #ffffff;
}

.navbar-nav .nav-link.active::after {
  content: '';
  display: block;
  width: 75px;
  height: 2px;
  background-color: #E0A74F;
  position: absolute;
  bottom: 0;
  left: 0;
}

.active {
  color: #E0A74F !important;
}

.dropdown-menu {
  min-width: 200px;
}

.nav-item {
  min-width: 81px;
}

.nav-item .nav-link {
  display: flex;
  align-items: center;
}

.nav-item .nav-link i {
  margin-right: 0.5rem;
}

.phnumber {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.phnumber i {
  margin-right: 0.5rem;
}

.dropdown-toggle::after {
  color: #E0A74F !important;
}

.bi-telephone {
  color: #E0A74F !important;
}

.navbar-nav {
  text-align: center !important;
}

#root {
  background: #0e0d0d;
}

.navbar-toggler-icon {
  filter: invert(1)s;
}

/* Footer CSS */
.footer {
  background: #231c1c;
  padding-top: 30px;
}

.footer h5 {
  color: #E0A74F;
}

.footer p,
a {
  color: #ffffff;
  text-decoration: none;
}

.foot-contact i,
.social i,
.yello-text {
  color: #E0A74F !important;
}

.white-text {
  color: #ffffff;
}

.copyrights {
  color: #ffffff;
}

/* Brown background */
.bg-brown {
  background: #231c1c;
}

/* Home baner style */
.home_banner_image img {
  width: 100%;
}

.home-banner-text h6 {
  border: 1px solid #ffffff;
  width: fit-content;
  padding: 8px 15px;
  border-radius: 10px;
  font-size: 14px;
}

.home-banner-text {
  font-size: 13px;
}

/* Contact nav styles */
.contact-nav {
  display: flex;
  list-style: none;
  padding: 15px 0 0;
}

.contact-nav a,
.contact-nav i {
  color: #000000 !important;
}

.contact-nav i {
  margin-left: 5px;
}

/* Subscription form styles */
.bg-gray {
  background: #3E3E3E;
}

.subscription-form {
  background: #3e3636;
  width: fit-content;
  margin: 30px auto 10px;
  border-radius: 25px;
  overflow: hidden;
  padding: 10px 15px;
}

.subscription-form input,
.subscription-form [type=submit] {
  border: none;
  background: none;
  color: #ffffff;
}

.subscription-form [type=submit]:focus-visible {
  border: none !important;
  outline: none !important;
}

/* About section styles */
.about-section p {
  font-size: 17px;
}

/* Trust RANAV Section styles */
.trust-cards img {
  width: 42px;
}

.trust-cards {
  max-width: 300px;
  margin: 0 auto;
}

.trust-cards p,
.text17 {
  font-size: 17px;
}

.trust-cards h6 {
  font-size: 18px;
}

.highlites-img img {
  width: 500px;
}

.highlites .trust-cards {
  word-break: keep-all;
}

.space-top10 {
  padding-top: 10px;
}

/* Categories slide section  */
.category-card {
  max-width: 160px;
}

.category-card img {
  width: 42px;
  margin: 0 auto;
}

.cat-btn {
  background: none;
  border: 1px solid #E0A74F;
  border-radius: 6px;
  padding: 10px 20px;
}

.cat-btn:hover {
  border: 1px solid #ffffff;
  color: #ffffff !important;
  transition-duration: 0.2s;
}

.slick-list {
  border-radius: 10px !important;
  z-index: 0 !important;
}

.slick-arrow {
  z-index: 1 !important;
}

/* Testimonial CSS stylings  */
.testimonial-card {
  background: #E7C873;
}

.rounded-circle-testimonial {
  width: 60px;
  border-radius: 30px;
}

.testimonial-title h6 {
  font-weight: bold !important;
}

.testimonial-title p {
  font-size: 14px;
}

/* Best Properties styles */
.slick-prev:before,
.slick-next:before {
  color: #ffffff;
  padding: 4px 18px;
  background: #7e7b7a;
  border-radius: 21px;

}

.slick-next::before {
  font-family: 'Bootstrap Icons';
  content: "\23F5";
}

.slick-prev {
  left: 25px !important;

}

.slick-prev:before {
  font-family: 'Bootstrap Icons';
  content: "\23F4";
}

.slick-next {
  right: 50px !important;
}

.video-thumbnail {
  position: relative;
  display: inline-block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: none;
  cursor: pointer;
}

.properties-card {
  background: #E7C873;
}

.properties-card p {
  font-size: 14px;
}

.download {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 5px;
  border: 1px solid #3e3636;
}

/* Leader section  */

/* grid image css starts */
.grid-img-sec {
  margin: 60px
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  display: inline-block;
}

/* Main CSS */
.grid-wrapper>div {
  display: flex;
  justify-content: center;
  align-items: center;

}

.grid-wrapper>div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.grid-wrapper {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 300px;
  grid-auto-flow: dense;
}

.grid-wrapper .wide {
  grid-column: span 1;

}

.grid-wrapper .tall {
  grid-row: span 2;
}

.grid-wrapper .tall2 {
  grid-row: span 2;
}

.grid-wrapper .big {
  grid-column: span 2;
  grid-row: span 2;
}

/* grid image css ends */
.leader-vmvsec {
  margin-top: 40px;
}

.vmv-sec {
  text-align: center;
}

.vmv {
  color: #D7A046;
  font-size: 18px;
  margin: 0px;
  margin-top: 10px;
  font-weight: bold;
}

.vmv-des {
  color: #fff;
  text-align: justify !important;
  display: block;
}


.leader-consulting-container {
  display: flex;
  background-color: #000;
  color: #fff;
  padding: 150px 0px 50px;
  align-items: center;
  justify-content: center;
}

.black-bg20 {
  background-color: #000;
  height: 40px;
}

.image-section {
  /* background: #3E3E3E; */
  /* position: relative; */
  margin-right: 20px;
  /* box-shadow: -167px -47px 0px 48px #3E3E3E; */
}

.leader-image {
  width: 100%;
  height: auto;
  /* border-radius: 10px; */
}

.image-overlay-text {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 4rem;
  font-weight: bold;
  color: #D7A046;
  z-index: 2;
}

.text-section {
  max-width: 600px;
  color: #fff;
}

h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #D7A046;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 10px;
  color: #fff;
}

.read-more-link {
  color: #D7A046;
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.read-more-link::after {
  content: '';
  margin-left: 5px;
}

.read-more-link i {
  transition: transform 0.3s ease;
}

.read-more-link:hover i {
  transform: rotate(-90deg);
  transition-duration: 1m;
}

/* contac us css starts */
.banner-space {
  height: 250px;
  background-color: #201b21;
}

.fpe-inputs {
  border: 1px solid #e0a74f;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #0e0d0d;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  outline: none;
}

.form-container {
  padding: 10px 50px;
}

.contact-txt {
  font-size: 40px;
  color: #e0a74f;
  font-weight: bold;
  margin-bottom: 20px;
}

.txt-ar {
  border: 1px solid #e0a74f;
  padding: 5px 10px;
  border-radius: 10px;
  background-color: #0e0d0d;
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  resize: none;
  outline: none;
}

.sub-btn {
  border: 1px solid #fff;
  background-color: #e0a74f;
  outline: navajowhite;
  border-radius: 10px;
  font-size: 15px;
  width: 80px;
}

.contact-img {
  margin-top: 73px;
  height: 250px;
  width: 500px;
}

.accordian-section {
  padding: 0px 50px;
}

.askd-txt {
  font-size: 30px;
  color: #e0a74f;
  font-weight: bold;
}

.accordians {
  margin: 5px 50px;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: #0e0d0d !important;
  border: 1px solid #e0a74f !important;
  border-radius: 10px !important;
  margin-bottom: 10px !important;
  color: #fff !important;
}

.accordion-button {
  background-color: #0e0d0d;
  border-radius: 10px;
  color: #fff;
}

.accordion-button:not(.collapsed) {

  background-color: #0e0d0d !important;
  color: #fbfbfb;
}

.accordion-button:focus {
  box-shadow: none
}

.accordion-item:first-of-type>.accordion-header .accordion-button {
  border-radius: 10px;
  padding-bottom: 10px;
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
  border-radius: 10px;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  padding-bottom: 0px;
}

.accordion-body {
  padding-top: 10px;
}

/* contact us css starts  ends*/

/* Gallery page */
.gallery-tab-container {
  background-color: #111;
  /* Dark background to match */
  padding: 20px;
  color: #fff;
  /* White text color */
}

.gallery-tabs .nav-link {
  color: #fff;
  /* White color for inactive tabs */
  border-radius: 0;
  padding: 10px 20px;
}

.gallery-tabs .nav-link.active {
  background-color: #cba342;
  /* Golden color for active tab */
  color: #fff;
  border-radius: 25px;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px;
  /* Adjust for gutter */
}

.my-masonry-grid_column {
  padding-left: 30px;
  /* Adjust for gutter */
  background-clip: padding-box;
}

.masonry-item {
  margin-bottom: 30px;
}

.img-fluid {
  width: 100%;
  height: auto;
  border-radius: 10px;
  /* Optional, for rounded corners */
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination {
  background-color: #222;
  /* Match pagination with dark theme */
  border-radius: 20px;
  padding: 10px;
}

.pagination .page-item.active .page-link {
  background-color: #cba342;
  /* Golden active page */
  border: none;
}

.pagination .page-link {
  color: #fff;
  background-color: transparent;
  border: none;
}

.pagination .page-link:hover {
  background-color: #444;
  /* Hover effect */
}

/* projects css starts */
.main-content {
  display: flex;
  color: #ffffff;
  padding: 10px;
  background-color: #ffffff;
}

.left-content {
  width: 350px;
  text-align: center;
}

.right-content {
  width: calc(100vw - 350px);
}

.left-logo {
  margin-top: 50px;
}

.left-logo img {
  width: 200px;
}

.left-sub-content {
  display: flex;
  padding: 20px 50px 0px;
  align-items: center;
}

.sub-icon {
  width: 50px;
}

.stamp {
  margin-top: 30px;
}

.sub-icon i {
  color: #E0A74F !important;
  font-size: 20px;
}

.sub-title p {
  padding-left: 15px;
  color: #000;
  margin: 0px;
  text-align: left;
  font-size: 14px;
}

.foot-title-left {
  margin-top: 30px;
}

.foot-title-left p {
  margin: 0px;
  font-size: 18px;
  font-weight: bold;
  color: #E0A74F;
  text-decoration: underline;
}

.projects {
  display: flex;
  justify-content: center;
}

.projects a {
  padding-left: 15px;
  color: #E0A74F;
}

/* projects css ends */

.dropdown-item {
  font-weight: 500;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #E0A74F !important;
  font-weight: bold;
}

/* Media query */
@media (max-width:480px) {
  .mob-center div {
    text-align: center;
  }

  .mob-center div h6 {
    margin: 50px auto 0;
  }

  .pt30 {
    padding-top: 20px;
  }

  .home-banner-text {
    margin-top: 50px;
  }
}
@media (max-width:550px) {
  .video-banner-container{
    height: 240px!important;
  }
  .fw-bold{
    font-size: 22px;
    padding-top: 70px;
  }
}



.video-banner-container {
  position: relative;
  width: 100%;
  height: 124vh; /* Full viewport height */
  overflow: hidden;
}

.video-banner {
  z-index: 1; /* Video above thumbnail */
  object-fit: cover;
}

.video-thumbnail {
  z-index: 0; /* Thumbnail below overlay */
  object-fit: cover;
  transition: opacity 0.5s ease-in-out; /* Smooth fade-out */
}

.video-thumbnail.hidden {
  opacity: 0; /* Makes thumbnail disappear smoothly */
  pointer-events: none; /* Disables interactions with hidden thumbnail */
}

.video-banner-overlay {
  z-index: 2; /* Overlay remains on top */
  /* background: rgba(0, 0, 0, 0.4);  */
  text-align: center;
  padding: 15px;
  display: none;
}


/* .video-banner-overlay {
  background: rgba(0, 0, 0, 0.4); 
  z-index: 1;
  text-align: center;
} */


.promise-section {
  background-color: #0a0a0a; /* Black Background */
}

.yellow-text {
  color: #d4a017; /* Gold/Yellow Text */
  font-family: 'Serif', sans-serif;
  font-size: 2rem;
  font-weight: bold;
}

.small-heading {
  font-size: 0.9rem;
  font-weight: bold;
  opacity: 0.8;
}

.text-content {
  max-width: 50%;
}

.image-content {
  max-width: 50%;
  display: flex;
  justify-content: center;
}

.image-content img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
}
.contact-navigation{
  background: #f8f9fa;
 margin-top: -24px;
 z-index: 2;
 border-radius: 10px!important;
 overflow: hidden;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
  color:#e0a74f!important;
}